import { GDSStatus } from '@leagueplatform/genesis-core';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import { Coverage } from 'types/coverages-api.types';

export const CLIENT_ID = 'highmark-member-search-web';
/**
 * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
 */
export const TENANT_ID = 'highmark-member-search';

export const MEMBER_SEARCH_AUTH0_CONNECTION = 'highmark-member-search-saml';

export const AGENT_TOKEN_CLAIM_KEY = 'https://el/agent_access_token';

export enum TENANT_MAPPING {
  MY_HIGHMARK = 'MyHighmark',
  BENEFICITY = 'Beneficity',
  ALLMYHEALTH = 'AllMyHealth',
}

export type SUPPORT_APP_DETAILS = {
  labelId: string;
  ssoToolParamValue: string | null;
};

export const SUPPORT_APP_TENANT_MAP: Record<
  TENANT_MAPPING,
  SUPPORT_APP_DETAILS
> = {
  [TENANT_MAPPING.MY_HIGHMARK]: {
    labelId: 'MY_HIGHMARK',
    ssoToolParamValue: 'MyHighmark',
  },
  [TENANT_MAPPING.BENEFICITY]: {
    labelId: 'BENEFICITY',
    ssoToolParamValue: 'Beneficity',
  },
  [TENANT_MAPPING.ALLMYHEALTH]: {
    labelId: 'ALLMYHEALTH',
    ssoToolParamValue: 'AllMyHealth',
  },
};

export const getSupportAppTenantDetails = (coverage: Coverage) => {
  switch (coverage.ielTenant) {
    case TENANT_MAPPING.BENEFICITY:
      return SUPPORT_APP_TENANT_MAP[TENANT_MAPPING.BENEFICITY];
    case TENANT_MAPPING.ALLMYHEALTH:
      return SUPPORT_APP_TENANT_MAP[TENANT_MAPPING.ALLMYHEALTH];
    case TENANT_MAPPING.MY_HIGHMARK:
      return SUPPORT_APP_TENANT_MAP[TENANT_MAPPING.MY_HIGHMARK];
    default:
      return SUPPORT_APP_TENANT_MAP[TENANT_MAPPING.MY_HIGHMARK];
  }
};

export const getBaseSupportToolSsoUrl = () =>
  import.meta.env.VITE_SUPPORT_TOOL_SSO_SVC_URL;

export const GENDER_ID_MAP = {
  M: 'MALE',
  F: 'FEMALE',
  U: 'UNKNOWN',
} as const;

export const APP_ROUTES = {
  MEMBER_DETAILS: '/member-details',
  MEMBER_SEARCH: '/member-search',
  SIGN_IN: '/start-login',
  SIGN_OUT: '/sign-out',
};

export const COVERAGE_STATUS_MAPPING: Record<
  string,
  { labelId: string; badgeStatus: GDSStatus; order: number }
> = {
  active: {
    labelId: 'ACTIVE',
    badgeStatus: 'success',
    order: 1,
  },
  pending: {
    labelId: 'PENDING',
    badgeStatus: 'info',
    order: 2,
  },
  past: {
    labelId: 'PAST',
    badgeStatus: 'warning',
    order: 3,
  },
};

export const SHORT_DATE_FORMAT_UTC = {
  ...SHORT_DATE_FORMAT,
  timeZone: 'UTC',
};
