import {
  HeadingText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const CoverageHeader = ({
  headingId,
  url,
}: {
  headingId: string;
  url?: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      orientation="horizontal"
      verticalAlignment="top"
      horizontalAlignment="spaceBetween"
      css={{ width: '100%', marginBottom: '$quarter' }}
    >
      <HeadingText level="2" size="xl">
        {formatMessage({ id: headingId })}
      </HeadingText>
      {url && (
        <TextAction href={url} target="_blank" indicator="externalLink">
          {formatMessage({ id: 'GO_TO_APP' })}
        </TextAction>
      )}
    </StackLayout>
  );
};
