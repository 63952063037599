// Member Search (Tenant Finder) assets overrides
import ErrorIcon from '../assets/error-icon.svg';

const WEB_COMMON_ASSETS = {
  WEB_COMMON_ERROR_STATE_IMAGE: ErrorIcon,
};

export const ASSETS_CONFIG = {
  ...WEB_COMMON_ASSETS,
};
