import {
  COVERAGE_STATUS_MAPPING,
  getSupportAppTenantDetails,
} from 'common/constants';
import { Coverage, CoverageResponse, Line } from 'types/coverages-api.types';
import { MemberResponseObject } from 'types/members-api.types';
import { getCSAppSsoUrl } from './support-app-sso-helpers';

export type CoverageByTenant = {
  [key: string]: {
    appNameId: string;
    appUrl: string | null;
    active: boolean;
    lines: {
      lineOfBusiness: string;
      clientId: string;
      clientName: string;
      groupId: string;
      effectiveDate: string;
      cancelDate: string;
    }[];
  };
};

export const getLineDetails = (line: Line, coverage: Coverage) => ({
  lineOfBusiness: line.lineOfBusiness,
  clientId: coverage.clientNumber,
  clientName: coverage.clientName,
  groupId: coverage.groupNumber,
  effectiveDate: line.effectiveDate,
  cancelDate: line.cancelDate,
});

// Coverage response sends separate items of coverage based on client/group info, but all belong under the same tenant
// This is to group them together by tenant, and add any member/app metadata needed.
export const groupCoverageByTenant = (
  data: CoverageResponse,
  member: MemberResponseObject,
) => {
  const coverageByTenant: CoverageByTenant = {};

  data.forEach((coverage) => {
    const { labelId, ssoToolParamValue } = getSupportAppTenantDetails(coverage);

    if (coverageByTenant[labelId]) {
      coverageByTenant[labelId].lines = [
        ...coverageByTenant[labelId].lines,
        ...coverage.lines.map((line) => getLineDetails(line, coverage)),
      ];
    } else {
      let appUrl = null;

      if (member.loginId && ssoToolParamValue) {
        appUrl = getCSAppSsoUrl({
          toolName: ssoToolParamValue,
          loginId: member.loginId,
          ecId: member.ecId,
        });
      }

      coverageByTenant[labelId] = {
        appNameId: labelId,
        appUrl,
        active: coverage.active,
        lines: coverage.lines.map((line) => getLineDetails(line, coverage)),
      };
    }
  });
  return coverageByTenant;
};

export const getCoverageStatusDetails = (line: Line, active: boolean) => {
  const startDate = new Date(line.effectiveDate);
  const endDate = line.cancelDate ? new Date(line.cancelDate) : null;
  const currentDate = new Date();

  // If start date is before today, and end date is null or end date is after today
  if (
    startDate < currentDate &&
    (!endDate || (endDate && endDate > currentDate))
  ) {
    return COVERAGE_STATUS_MAPPING.active;
  }

  // If endDate exists and is before today
  if (!active && endDate && endDate < currentDate) {
    return COVERAGE_STATUS_MAPPING.past;
  }

  // If start date is after today
  if (startDate > currentDate) {
    return COVERAGE_STATUS_MAPPING.pending;
  }

  return COVERAGE_STATUS_MAPPING.active;
};
