import { StackLayout, Tabs, styled } from '@leagueplatform/genesis-core';
import {
  LoadingIndicator,
  MainPageContainer,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { ERROR_TYPES, ErrorState } from '@highmark-web/highmark-common';
import { PersonalInfoTab } from './personal-info/personal-info-tab.component';
import { useGetMemberDetails } from './use-get-member-details.hook';
import { CoverageDataTab } from './coverage-data/coverage-data-tab.component';
import { MemberDetailsHeader } from './member-details-header.component';

const TABS = {
  PERSONAL_INFO: 'PERSONAL_INFORMATION',
  COVERAGE_DATA: 'COVERAGE_DATA',
};

const StyledTabPanel = styled(Tabs.TabPanel, {
  width: '100%',
  marginTop: '$two',
});

export const MemberDetailsTabButtons = () => {
  const { formatMessage } = useIntl();

  return (
    <Tabs.TabList>
      <StackLayout orientation="horizontal">
        {Object.values(TABS).map((tab) => (
          <Tabs.Tab
            value={tab}
            css={{
              padding: '$one',
              margin: '$quarter',
              cursor: 'pointer',
              fontWeight: '$subtitleOne',
              fontSize: '$subtitleOne',
            }}
            key={tab}
          >
            {formatMessage({ id: tab })}
          </Tabs.Tab>
        ))}
      </StackLayout>
    </Tabs.TabList>
  );
};

export const MemberDetailsPage = () => {
  const { data, isLoading, isError } = useGetMemberDetails();

  return (
    <MainPageContainer marginTop="three">
      <StackLayout spacing="$two">
        {/* Header Section */}
        <MemberDetailsHeader personalInfo={data?.personalInfo} />
        {/* Tabs */}
        {!isLoading && !isError && data && (
          <Tabs.Root defaultValue={TABS.PERSONAL_INFO}>
            <MemberDetailsTabButtons />
            {/* Tab Content Panels */}
            <StyledTabPanel value={TABS.PERSONAL_INFO}>
              <PersonalInfoTab
                personalInfo={data.personalInfo}
                coverageData={data.coverages}
              />
            </StyledTabPanel>
            <StyledTabPanel value={TABS.COVERAGE_DATA}>
              <CoverageDataTab coverageData={data.coverages} />
            </StyledTabPanel>
          </Tabs.Root>
        )}
        {isLoading && <LoadingIndicator />}
        {isError && (
          <StackLayout
            horizontalAlignment="center"
            css={{ width: '100%', marginTop: '$three' }}
          >
            <ErrorState errorType={ERROR_TYPES.API_ERROR} />
          </StackLayout>
        )}
      </StackLayout>
    </MainPageContainer>
  );
};
