import {
  Button,
  ParagraphText,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { MemberResponseObject } from 'types/members-api.types';
import { useState } from 'react';
import {
  SearchResultHeader,
  SearchResultRow,
} from './search-result.row.component';

const SearchResultWrapper = styled('ul', {
  width: '100%',
});

export const SearchResults = ({
  members,
}: {
  members: MemberResponseObject[];
}) => {
  const { formatMessage } = useIntl();
  const [isViewMore, setIsViewMore] = useState(members.length <= 5);
  const parsedMembers = !isViewMore ? members?.slice(0, 5) : members;

  if (!members || members.length === 0) {
    return (
      <ParagraphText size="sm">
        {formatMessage({ id: 'NO_RESULTS_FOUND' })}
      </ParagraphText>
    );
  }

  return (
    <StackLayout>
      <SearchResultHeader />
      <SearchResultWrapper>
        {parsedMembers.map((memberDetails) => (
          <SearchResultRow memberDetails={memberDetails} />
        ))}
      </SearchResultWrapper>
      {!isViewMore && (
        <Button
          priority="secondary"
          size="medium"
          css={{ marginTop: '$one' }}
          onClick={() => setIsViewMore(true)}
        >
          {formatMessage({ id: 'VIEW_MORE' })}
        </Button>
      )}
    </StackLayout>
  );
};
