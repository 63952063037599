import {
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { AppLinkoutCard } from 'components/app-linkout-card/app-linkout-card.component';
import {
  InfoSectionData,
  InfoSection,
} from 'components/info-section/info-section.component';
import { useIntl } from '@leagueplatform/locales';
import { CoverageByTenant } from 'utils/coverage-helpers';
import { PersonalInfoType } from '../use-get-member-details.hook';

const getFormattedPersonalInfoSection = (
  data: PersonalInfoType,
): InfoSectionData => [
  [
    {
      labelId: 'DATE_OF_BIRTH',
      value: data?.dob,
    },
    {
      labelId: 'GENDER',
      value: data?.gender,
    },
  ],
  [
    {
      labelId: 'RELATIONSHIP_CODE',
      value: data?.relationship,
    },
    {
      labelId: 'UMI',
      value: data?.umi,
    },
  ],
];

export const PersonalInfoTab = ({
  personalInfo,
  coverageData,
}: {
  personalInfo: PersonalInfoType;
  coverageData: CoverageByTenant;
}) => {
  const personalInfoSection = getFormattedPersonalInfoSection(personalInfo);
  const { formatMessage } = useIntl();

  const eligibleApps = Object.values(coverageData).filter(
    (tenant) => tenant.appUrl,
  );

  return (
    <StackLayout spacing="$two">
      <InfoSection data={personalInfoSection} />

      {eligibleApps.length > 0 && (
        <StackLayout spacing="$quarter">
          <HeadingText level="3" size="lg">
            {formatMessage({
              id: 'MEMBER_DETAILS_LINKOUT_SECTION_HEADING',
            })}
          </HeadingText>
          <ParagraphText size="sm">
            {formatMessage(
              {
                id: 'MEMBER_DETAILS_LINKOUT_SECTION_SUBHEADING',
              },
              { name: personalInfo.fullName },
            )}
          </ParagraphText>
        </StackLayout>
      )}

      <StackLayout
        orientation="horizontal"
        css={{
          width: '100%',
        }}
        spacing="$two"
      >
        {eligibleApps.map(({ appNameId, appUrl }) => {
          const label = formatMessage(
            {
              id: 'SUPPORT_APP_LINKOUT_CARD_HEADING',
            },
            { app: formatMessage({ id: appNameId }) },
          );

          return (
            <StackItem
              css={{
                flexGrow: '1',
                width: '100%',
                maxWidth: 'calc(50% - $one)',
              }}
              key={appNameId}
            >
              <AppLinkoutCard text={label} url={appUrl || ''} />
            </StackItem>
          );
        })}
      </StackLayout>
    </StackLayout>
  );
};
