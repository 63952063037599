import {
  Fieldset,
  HeadingText,
  Radio,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { Formik } from 'formik';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useEffect, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import {
  FORM_TYPE,
  MemberSearchFormProps,
  useMemberSearchFormStore,
} from 'store/member-search-form-store';
import { useQueryClient } from 'react-query';
import {
  validateUmiFormValues,
  validateNameAndBirthDateFormValues,
  validateEcidFormValues,
} from 'utils/form-input-validators';
import {
  MEMBER_SEARCH_QUERY_KEY,
  useGetMemberSearch,
} from './use-member-search';
import { SearchResults } from './search-results/search-results.component';
import { SearchForm } from './search-form/search-form.component';

const validateSearchForm = (values: MemberSearchFormProps) => {
  let errors: Partial<MemberSearchFormProps> = {};

  switch (values.formType) {
    case FORM_TYPE.UMI:
      errors = { ...errors, ...validateUmiFormValues(values) };
      break;
    case FORM_TYPE.NAME_DOB:
      errors = { ...errors, ...validateNameAndBirthDateFormValues(values) };
      break;
    case FORM_TYPE.ECID:
      errors = { ...errors, ...validateEcidFormValues(values) };
      break;
    default:
      break;
  }
  return errors;
};

const initialFormValues: MemberSearchFormProps = {
  umi: '',
  firstName: '',
  lastName: '',
  month: '',
  day: '',
  year: '',
  ecid: '',
  formType: FORM_TYPE.UMI,
};

export const MemberSearchPage = () => {
  const { memberSearchFields, setMemberSearchFields } =
    useMemberSearchFormStore();
  const [enableQuery, setEnableQuery] = useState(false);
  const { data, isError, isFetching } = useGetMemberSearch(
    memberSearchFields,
    enableQuery,
  );
  const queryClient = useQueryClient();

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isError) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_MEMBER_SEARCH',
      });
    }
  }, [isError]);

  return (
    <MainPageContainer marginTop="three">
      <StackLayout spacing="$two">
        <StackItem>
          <HeadingText
            level="1"
            size="xxl"
            css={{ marginBottom: '$threeQuarters' }}
          >
            {formatMessage({ id: 'MEMBER_SEARCH' })}
          </HeadingText>
        </StackItem>

        <Formik
          initialValues={
            (memberSearchFields || initialFormValues) as MemberSearchFormProps
          }
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values: MemberSearchFormProps) =>
            validateSearchForm(values)
          }
          onSubmit={(values: MemberSearchFormProps, actions) => {
            actions.setSubmitting(false);
            queryClient.invalidateQueries({
              queryKey: [MEMBER_SEARCH_QUERY_KEY],
            });
            setEnableQuery(true);
            setMemberSearchFields(values);
          }}
        >
          {(formProps) => (
            <>
              <StackItem>
                <HeadingText
                  size="lg"
                  level="display"
                  css={{ paddingBottom: '$half' }}
                >
                  Search By
                </HeadingText>
                <Fieldset
                  legend="Form type"
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    '.GDS-radio-label': { fontSize: '$label' },
                  }}
                >
                  <Radio
                    id="umi-radio"
                    name="formType"
                    value={FORM_TYPE.UMI}
                    label={formatMessage({
                      id: 'UMI_RADIO_LABEL',
                    })}
                    checked={memberSearchFields?.formType === FORM_TYPE.UMI}
                    onChange={(e) => {
                      setMemberSearchFields({
                        formType: FORM_TYPE.UMI,
                      });
                      setEnableQuery(false);
                      formProps.resetForm({ values: initialFormValues });
                      formProps.handleChange(e);
                    }}
                  />
                  <Radio
                    id="name-dob-radio"
                    name="formType"
                    value={FORM_TYPE.NAME_DOB}
                    checked={
                      memberSearchFields?.formType === FORM_TYPE.NAME_DOB
                    }
                    label={formatMessage({
                      id: 'FULL_NAME_AND_BIRTH_DATE_RADIO_LABEL',
                    })}
                    onChange={(e) => {
                      setMemberSearchFields({
                        formType: FORM_TYPE.NAME_DOB,
                      });
                      setEnableQuery(false);
                      formProps.resetForm({ values: initialFormValues });
                      formProps.handleChange(e);
                    }}
                  />
                  <Radio
                    id="ecid-radio"
                    name="formType"
                    value={FORM_TYPE.ECID}
                    checked={memberSearchFields?.formType === FORM_TYPE.ECID}
                    label={formatMessage({ id: 'ECID_RADIO_LABEL' })}
                    onChange={(e) => {
                      setMemberSearchFields({
                        formType: FORM_TYPE.ECID,
                      });
                      setEnableQuery(false);
                      formProps.resetForm({ values: initialFormValues });
                      formProps.handleChange(e);
                    }}
                  />
                </Fieldset>
              </StackItem>
              <SearchForm
                formProps={formProps}
                isLoading={isFetching}
                formType={memberSearchFields?.formType!}
              />
            </>
          )}
        </Formik>
        {!isFetching && data && !!data.members && (
          <StackItem css={{ width: '100%' }}>
            <SearchResults members={data.members} />
          </StackItem>
        )}
      </StackLayout>
    </MainPageContainer>
  );
};
