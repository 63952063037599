import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export type InfoSectionData = {
  labelText?: string;
  labelId?: string;
  value?: string;
}[][];

export type InfoSectionProps = {
  data: InfoSectionData;
};

export const InfoSection = ({ data }: InfoSectionProps) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      orientation="horizontal"
      css={{
        width: '100%',
      }}
      spacing="$two"
    >
      {data.map((column) => (
        <StackLayout
          as="ul"
          css={{
            flexGrow: '1',
          }}
          key={column[0]?.labelId}
        >
          {column.map((item, index) => (
            <StackLayout
              as="li"
              key={item.labelId}
              orientation="horizontal"
              horizontalAlignment="spaceBetween"
              css={{
                flexGrow: '1',
                width: '100%',
                borderBottom:
                  index < column.length - 1
                    ? '$onSurfaceBorderSubdued 1px solid'
                    : 'none',
                marginBottom: '$half',
              }}
            >
              <ParagraphText css={{ color: '$onSurfaceTextSubdued' }} size="sm">
                {item.labelId
                  ? formatMessage({ id: item.labelId })
                  : item.labelText}
              </ParagraphText>
              <ParagraphText size="sm" css={{ textTransform: 'capitalize' }}>
                {item.value}
              </ParagraphText>
            </StackLayout>
          ))}
        </StackLayout>
      ))}
    </StackLayout>
  );
};
