import { StandaloneAuth } from '@leagueplatform/auth-standalone';

import { useEffect, useState } from 'react';

export const useAgentName = () => {
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    StandaloneAuth.client.getUser().then((user) => {
      const name = `${user?.given_name || ''} ${user?.family_name || ''}`;
      setFullName(name);
    });
  }, []);

  return { fullName };
};
