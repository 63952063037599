import { TextAction } from '@leagueplatform/genesis-core';

export const BackButton = ({
  onClick,
  buttonText,
}: {
  onClick: () => void;
  buttonText: string;
}) => (
  <TextAction
    onClick={onClick}
    as="button"
    icon="interfaceChevronLeft"
    css={{
      color: '$onSurfaceIconPrimary',
    }}
  >
    {buttonText}
  </TextAction>
);
