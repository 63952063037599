import { create } from 'zustand';

export enum FORM_TYPE {
  UMI = 'UMI',
  NAME_DOB = 'NAME_DOB',
  ECID = 'ECID',
}

export type MemberSearchFormProps = {
  umi: string;
  firstName: string;
  lastName: string;
  month: string;
  day: string;
  year: string;
  ecid: string;
  formType: FORM_TYPE;
};

export interface MemberSearchFormState {
  memberSearchFields?: Partial<MemberSearchFormProps>;
  setMemberSearchFields: (
    member: Partial<MemberSearchFormProps> | undefined,
  ) => void;
}

export const useMemberSearchFormStore = create<MemberSearchFormState>(
  (set) => ({
    memberSearchFields: {
      formType: FORM_TYPE.UMI,
    },
    setMemberSearchFields: (member) => set({ memberSearchFields: member }),
  }),
);
