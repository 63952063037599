import { useMemo } from 'react';
import {
  HeadingText,
  StackLayout,
  StatusBadge,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  InfoSectionData,
  InfoSection,
} from 'components/info-section/info-section.component';
import {} from 'utils/support-app-sso-helpers';
import {
  CoverageByTenant,
  getCoverageStatusDetails,
} from 'utils/coverage-helpers';
import { SHORT_DATE_FORMAT_UTC } from 'common/constants';
import { CoverageHeader } from './coverage-header.component';

const getFormattedPlanDetailsEntries = (plan: {
  clientId: string;
  groupId: string;
  clientName: string;
  effectiveDate: string;
  endDate: string;
}): InfoSectionData => [
  [
    { labelId: 'CLIENT_ID', value: plan.clientId },
    { labelId: 'GROUP_ID', value: plan.groupId },
    { labelId: 'CLIENT_NAME', value: plan.clientName },
  ],
  [
    { labelId: 'COVERAGE_START_DATE', value: plan.effectiveDate },
    { labelId: 'COVERAGE_END_DATE', value: plan.endDate },
  ],
];

export const CoverageDataTab = ({
  coverageData,
}: {
  coverageData: CoverageByTenant;
}) => {
  const { formatDate, formatMessage } = useIntl();

  const formattedCoverageData = useMemo(
    () =>
      Object.values(coverageData).map(
        ({ appNameId, appUrl, lines, active }) => ({
          appNameId,
          appUrl,
          linesData: lines
            .map((line) => ({
              name: line.lineOfBusiness,
              status: getCoverageStatusDetails(line, active),
              infoSection: getFormattedPlanDetailsEntries({
                clientId: line.clientId,
                groupId: line.groupId,
                clientName: line.clientName,
                effectiveDate: formatDate(
                  line.effectiveDate,
                  SHORT_DATE_FORMAT_UTC,
                ),
                endDate: line.cancelDate
                  ? formatDate(line.cancelDate, SHORT_DATE_FORMAT_UTC)
                  : '',
              }),
            }))
            .sort((a, b) => (a.status?.order || 4) - (b.status?.order || 4)),
        }),
      ),
    [coverageData, formatDate],
  );

  return (
    <StackLayout spacing="$three">
      {formattedCoverageData.map((coverage) => (
        <StackLayout
          spacing="$one"
          css={{ width: '100%' }}
          key={coverage.appNameId}
        >
          <CoverageHeader
            headingId={coverage.appNameId}
            url={coverage.appUrl || undefined}
          />
          {coverage.linesData.map((line) => (
            <StackLayout
              key={line.name}
              css={{
                width: '100%',
              }}
              spacing="$one"
            >
              <StackLayout
                orientation="horizontal"
                verticalAlignment="center"
                spacing="$half"
              >
                <HeadingText level="3" size="md">
                  {line.name}
                </HeadingText>
                {line.status && (
                  <StatusBadge
                    label={formatMessage({
                      id: line.status.labelId,
                    })}
                    status={line.status.badgeStatus}
                  />
                )}
              </StackLayout>
              <InfoSection data={line.infoSection} />
            </StackLayout>
          ))}
        </StackLayout>
      ))}
    </StackLayout>
  );
};
