import { MemberResponseObject } from 'types/members-api.types';
import { create } from 'zustand';

export interface MemberOfInterestState {
  member?: MemberResponseObject | undefined;
  setCurrentMember: (member: MemberResponseObject) => void;
}

export const useMemberOfInterestStore = create<MemberOfInterestState>(
  (set) => ({
    setCurrentMember: (member) => set({ member }),
  }),
);
