export const EN_STRING_OVERRIDES = {
  ALLMYHEALTH: 'AllMyHealth',
  BENEFICITY: 'Beneficity',
  COVERAGE_DATA: 'Coverage Data',
  COVERAGE_START_DATE: 'Coverage Start Date',
  COVERAGE_END_DATE: 'Coverage End Date',
  CLIENT_ID: 'Client Id',
  CLIENT_NAME: 'Client Name',
  GO_TO_APP: 'Go to app',
  GROUP_ID: 'Group Id',
  GROUP_NAME: 'Group Name',
  MEMBER_DETAILS: 'Member Details',
  MEMBER_DETAILS_LINKOUT_SECTION_HEADING:
    'Support Apps for Member Digital Accounts',
  MEMBER_DETAILS_LINKOUT_SECTION_SUBHEADING:
    "Here's where you can find more information and tools to support {name}.",
  ERROR_MEMBER_SEARCH:
    'An error occurred while searching for members. Please try again later.',
  MEMBER_SEARCH: 'Member Search',
  MY_HIGHMARK: 'My Highmark',
  NO_DIGITAL_ACCOUNT_BANNER_TEXT:
    'This member does not have a registered digital account',
  PERSONAL_INFORMATION: 'Personal Information',
  RELATIONSHIP_CODE: 'Relationship Code',
  RELOAD: 'Reload',
  SUPPORT_APP_LINKOUT_CARD_HEADING: '{app} Support App',
  UMI: 'UMI',
  ECID: 'ECID',
  UMI_RADIO_LABEL: 'Unique Member Identifier',
  UMI_INPUT_LABEL: 'Unique Member Identifier (UMI)',
  ECID_RADIO_LABEL: 'Enterprise Consumer Identifier',
  ECID_INPUT_LABEL: 'Enterprise Consumer Identifier (ECID)',
  FULL_NAME_AND_BIRTH_DATE_RADIO_LABEL: 'Full Name and Date of Birth',
  FORM_INPUT_ERROR_INVALID_UMI: 'Invalid UMI format',
  FORM_INPUT_ERROR_INVALID_BIRTH_DAY: 'Invalid day',
  FORM_INPUT_ERROR_INVALID_BIRTH_MONTH: 'Invalid month',
  FORM_INPUT_ERROR_INVALID_BIRTH_YEAR: 'Invalid year',
  FORM_INPUT_ERROR_INVALID_FIRST_NAME: 'Invalid first name',
  FORM_INPUT_ERROR_INVALID_LAST_NAME: 'Invalid last name',
  FORM_INPUT_ERROR_INVALID_ECID: 'Enter a valid Enterprise Consumer Identifier',
  FORM_INPUT_ERROR_EMPTY_UMI: 'Please enter a Unique Member Identifier',
  FORM_INPUT_ERROR_EMPTY_BIRTH_DATE: 'Please enter a date of birth',
  FORM_INPUT_ERROR_EMPTY_FIRST_NAME: 'Please enter a first name',
  FORM_INPUT_ERROR_EMPTY_LAST_NAME: 'Please enter a last name',
  FORM_INPUT_ERROR_EMPTY_ECID: 'Please enter an Enterprise Consumer Identifier',
  NO_MEMBER_ACCESS_ERROR:
    'Error retrieving member information: Member Access Error.',
  NO_RESULTS_FOUND: 'We couldn’t find anything matching the search criteria.',
};
