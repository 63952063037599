import {
  Card,
  HeadingText,
  TextAction,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const AppLinkoutCard = ({
  text,
  url,
}: {
  text: string;
  url: string;
}) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  const { formatMessage } = useIntl();

  return (
    <Card.Flat css={{ border: '$onSurfaceBorderSubdued 1px solid' }}>
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
      >
        <Card.Section padding="$one">
          <HeadingText
            level="4"
            size="md"
            css={{ marginBottom: '$threeQuarters' }}
          >
            {text}
          </HeadingText>
          <TextAction
            ref={primaryActionRef}
            as="a"
            indicator="externalLink"
            size="medium"
            href={url}
            target="_blank"
          >
            {formatMessage({ id: 'GO_TO_APP' })}
          </TextAction>
        </Card.Section>
      </Card.ClickArea>
    </Card.Flat>
  );
};
