import { MemberSearchFormProps } from 'store/member-search-form-store';

type UmiFormFields = Pick<MemberSearchFormProps, 'umi'>;

type NameAndBirthDateFormFields = Pick<
  MemberSearchFormProps,
  'firstName' | 'lastName' | 'day' | 'month' | 'year'
>;

type EcidFormFields = Pick<MemberSearchFormProps, 'ecid'>;

const isDateNumberRangeInvalid = (
  value: string,
  lowestValue: number,
  highestValue: number,
) => {
  // Check if number is integer
  if (!value.match(/^\d+$/)) {
    return true;
  }

  const numberValue = parseFloat(value);
  return numberValue < lowestValue || numberValue > highestValue;
};

export const validateUmiFormValues = (values: UmiFormFields) => {
  const errors: Partial<UmiFormFields> = {};
  if (values.umi && values.umi.length > 0) {
    if (!/^\d{13}$/.test(values.umi)) {
      errors.umi = 'FORM_INPUT_ERROR_INVALID_UMI';
    }
  } else {
    errors.umi = 'FORM_INPUT_ERROR_EMPTY_UMI';
  }

  return errors;
};

export const validateNameAndBirthDateFormValues = (
  values: NameAndBirthDateFormFields,
) => {
  const errors: Partial<NameAndBirthDateFormFields> = {};
  if (values.firstName && values.firstName.length > 0) {
    if (/\d/.test(values.firstName)) {
      errors.firstName = 'FORM_INPUT_ERROR_INVALID_FIRST_NAME';
    }
  } else {
    errors.firstName = 'FORM_INPUT_ERROR_EMPTY_FIRST_NAME';
  }

  if (values.lastName && values.lastName.length > 0) {
    if (/\d/.test(values.lastName)) {
      errors.lastName = 'FORM_INPUT_ERROR_INVALID_LAST_NAME';
    }
  } else {
    errors.lastName = 'FORM_INPUT_ERROR_EMPTY_LAST_NAME';
  }

  if (values.month) {
    if (isDateNumberRangeInvalid(values.month, 1, 12)) {
      errors.month = 'FORM_INPUT_ERROR_INVALID_BIRTH_MONTH';
    }
  } else {
    errors.month = 'FORM_INPUT_ERROR_EMPTY_BIRTH_DATE';
  }

  if (values.day) {
    if (isDateNumberRangeInvalid(values.day, 1, 31)) {
      errors.day = 'FORM_INPUT_ERROR_INVALID_BIRTH_DAY';
    }
  } else {
    errors.day = 'FORM_INPUT_ERROR_EMPTY_BIRTH_DATE';
  }

  if (values.year) {
    if (isDateNumberRangeInvalid(values.year, 1000, 2500)) {
      errors.year = 'FORM_INPUT_ERROR_INVALID_BIRTH_YEAR';
    }
  } else {
    errors.year = 'FORM_INPUT_ERROR_EMPTY_BIRTH_DATE';
  }

  return errors;
};

export const validateEcidFormValues = (values: EcidFormFields) => {
  const errors: Partial<EcidFormFields> = {};
  // Valid ECID = up to 10 numbers, can have leading zero
  // Minimum 1 number and maximum of 15 numbers.
  if (values.ecid && values.ecid.length > 0) {
    if (!/^[0]{0,14}\d{1,10}$/.test(values.ecid) || values.ecid.length > 15) {
      errors.ecid = 'FORM_INPUT_ERROR_INVALID_ECID';
    }
  } else {
    errors.ecid = 'FORM_INPUT_ERROR_EMPTY_ECID';
  }

  return errors;
};
