import {
  CoverageResponse,
  CoveragesRequestBody,
} from 'types/coverages-api.types';
import { memberEligibilityFetch } from './member-search-fetch';

export const MEMBER_COVERAGES_PATH = '/enrollment/v2/coverages';

// Some fields are not camelCase, this is to standardize the fields
export const normalizeResponseData = (data: any[]): CoverageResponse =>
  data.map((resultItem: any) => ({
    ...resultItem,
    ielTenant: resultItem?.ieltenant,
  }));

export const getCoverages = async (
  body: CoveragesRequestBody,
): Promise<CoverageResponse> => {
  const response = await memberEligibilityFetch(MEMBER_COVERAGES_PATH, {
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(
      `Error from request ${MEMBER_COVERAGES_PATH}: ${response.status}`,
    );
  }

  const data = await response.json();

  if (!data && !(data instanceof Array)) {
    throw new Error(
      `Returned response from ${MEMBER_COVERAGES_PATH} is not in expected array format.`,
    );
  }
  return normalizeResponseData(data);
};
