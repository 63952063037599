import { useQuery } from 'react-query';
import { getMemberSearch } from 'api/get-member-search';
import { useMemberSearchResultsStore } from 'store/member-search-results-store';
import { MemberSearchFormProps } from 'store/member-search-form-store';
import {
  MemberSearchRequestBody,
  MemberSearchResponse,
} from 'types/members-api.types';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { captureAppError } from '@leagueplatform/observability';

export const MEMBER_SEARCH_QUERY_KEY = 'hm_member_search';

export const useGetMemberSearch = (
  member: Partial<MemberSearchFormProps> | undefined,
  enabled: boolean = true,
) => {
  const { members, setMemberSearchResults } = useMemberSearchResultsStore();

  const constructDob = (selectedMember: Partial<MemberSearchFormProps>) =>
    `${selectedMember.year}-${selectedMember.month?.padStart(
      2,
      '0',
    )}-${selectedMember.day?.padStart(2, '0')}`;

  const query = useQuery(
    [MEMBER_SEARCH_QUERY_KEY, member],
    () => {
      if (!member) {
        throw new Error(
          'Invalid member search form, unable to retrieve member search data',
        );
      }
      const memberQueryData: MemberSearchRequestBody = {
        umi: member.umi,
        firstName: member.firstName,
        lastName: member.lastName,
        dob: member.day ? constructDob(member) : '',
        ecid: member.ecid,
      };
      return getMemberSearch(memberQueryData);
    },
    {
      enabled:
        (!!member?.umi ||
          !!(
            member?.firstName &&
            member?.lastName &&
            member?.day &&
            member?.month &&
            member?.year
          ) ||
          !!member?.ecid) &&
        enabled,
      onSuccess: (data: MemberSearchResponse) => {
        if (data?.length > 0) {
          let hasMemberAccessError = false;
          // Filter out members that have null values, this means agent does not have permissions.
          // If one or more members are filtered out, show error message.
          const validMembers = data.filter((memberInfo) => {
            if (
              !memberInfo?.firstName ||
              !memberInfo?.lastName ||
              !memberInfo?.dob ||
              !memberInfo?.umi
            ) {
              captureAppError(
                new Error('Member Search API error: FGAC access error'),
                {
                  errorName: 'Member Search API error: FGAC access error',
                  context: {
                    Context: {
                      fgac_outcome: memberInfo?.fgac_outcome,
                    },
                  },
                },
              );
              hasMemberAccessError = true;
              return false;
            }
            return true;
          });

          if (hasMemberAccessError) {
            toastActions.add({
              type: TOAST_STATUS.ERROR,
              textId: 'NO_MEMBER_ACCESS_ERROR',
            });
            if (validMembers.length > 0) {
              setMemberSearchResults(validMembers);
            } else {
              setMemberSearchResults(undefined);
            }
            return;
          }
        }
        setMemberSearchResults(data);
      },
      onError: () => {
        setMemberSearchResults(undefined);
      },
    },
  );
  return {
    ...query,
    data: {
      members,
    },
  };
};
