import { Form, FormikProps } from 'formik';
import {
  FORM_TYPE,
  MemberSearchFormProps,
} from 'store/member-search-form-store';
import React from 'react';
import { Button, StackItem, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { UmiSearchForm } from './umi-search-form.component';
import { NameDobSearchForm } from './name-dob-search-form.component';
import { EcidSearchForm } from './ecid-search-form.component.component';

export const SearchForm = ({
  formProps,
  formType,
  isLoading,
}: {
  formProps: FormikProps<MemberSearchFormProps>;
  formType: FORM_TYPE;
  isLoading: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form onSubmit={formProps.handleSubmit}>
      <StackLayout spacing="$two">
        {formType === FORM_TYPE.UMI && <UmiSearchForm formProps={formProps} />}
        {formType === FORM_TYPE.NAME_DOB && (
          <NameDobSearchForm formProps={formProps} />
        )}
        {formType === FORM_TYPE.ECID && (
          <EcidSearchForm formProps={formProps} />
        )}

        <StackItem>
          <Button
            css={{
              marginTop: '$quarter',
              width: isLoading ? '150px' : '118px',
            }}
            type="submit"
            loading={isLoading}
          >
            {formatMessage({ id: 'SEARCH' })}
          </Button>
        </StackItem>
      </StackLayout>
    </Form>
  );
};
