import { MemberResponseObject } from 'types/members-api.types';
import { create } from 'zustand';

export interface MemberSearchResultsState {
  members?: MemberResponseObject[];
  setMemberSearchResults: (members: MemberResponseObject[] | undefined) => void;
}

export const useMemberSearchResultsStore = create<MemberSearchResultsState>(
  (set) => ({
    setMemberSearchResults: (members) => set({ members }),
  }),
);
