import {
  HeadingText,
  InputLabel,
  InputStatusMessage,
  StackItem,
  StackLayout,
  TextInput,
} from '@leagueplatform/genesis-core';
import { FormikProps } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { MemberSearchFormProps } from 'store/member-search-form-store';
import React from 'react';
import { genesisStyled } from '@leagueplatform/genesis-commons';

const StyledDateInputWrapper = genesisStyled('div')`
    .GDS-base-input {
      max-width: 60px
    }
`;

export const NameDobSearchForm = ({
  formProps,
}: {
  formProps: FormikProps<MemberSearchFormProps>;
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StackItem>
        <HeadingText
          size="xs"
          level="3"
          css={{ marginBottom: '$threeQuarters' }}
        >
          {formatMessage({ id: 'FULL_NAME' })}
        </HeadingText>
        <StackLayout orientation="horizontal" spacing="$oneAndHalf">
          <StackItem>
            <InputLabel htmlFor="firstName" required>
              {formatMessage({ id: 'FIRST_NAME' })}
            </InputLabel>
            <TextInput
              id="firstName"
              type="text"
              css={{ minWidth: '437px' }}
              value={formProps.values.firstName}
              onChange={formProps.handleChange}
            />
            {formProps.errors.firstName && (
              <InputStatusMessage id="firstName" inputStatus="error">
                {formatMessage({ id: formProps.errors.firstName })}
              </InputStatusMessage>
            )}
          </StackItem>

          <StackItem>
            <InputLabel htmlFor="lastName" required>
              {formatMessage({ id: 'LAST_NAME' })}
            </InputLabel>
            <TextInput
              id="lastName"
              type="text"
              css={{ minWidth: '437px' }}
              value={formProps.values.lastName}
              onChange={formProps.handleChange}
            />
            {formProps.errors.lastName && (
              <InputStatusMessage id="lastName" inputStatus="error">
                {formatMessage({ id: formProps.errors.lastName })}
              </InputStatusMessage>
            )}
          </StackItem>
        </StackLayout>
      </StackItem>

      <StackItem>
        <HeadingText
          size="xs"
          level="3"
          css={{ marginBottom: '$threeQuarters' }}
        >
          {formatMessage({ id: 'DATE_OF_BIRTH' })}
        </HeadingText>
        <StackLayout orientation="horizontal" spacing="$one">
          <StackItem>
            <InputLabel htmlFor="month" required>
              {formatMessage({ id: 'MONTH' })}
            </InputLabel>
            <StyledDateInputWrapper>
              <TextInput
                id="month"
                maxLength={2}
                placeholder="MM"
                value={formProps.values.month}
                onChange={formProps.handleChange}
              />
            </StyledDateInputWrapper>
          </StackItem>
          <StackItem>
            <InputLabel htmlFor="day" required>
              {formatMessage({ id: 'DAY' })}
            </InputLabel>
            <StyledDateInputWrapper>
              <TextInput
                id="day"
                placeholder="DD"
                maxLength={2}
                value={formProps.values.day}
                onChange={formProps.handleChange}
              />
            </StyledDateInputWrapper>
          </StackItem>
          <StackItem>
            <InputLabel htmlFor="year" required>
              {formatMessage({ id: 'YEAR' })}
            </InputLabel>
            <TextInput
              id="year"
              placeholder="YYYY"
              maxLength={4}
              value={formProps.values.year}
              onChange={formProps.handleChange}
              css={{
                '.GDS-base-input': {
                  maxWidth: '70px',
                },
              }}
            />
          </StackItem>
        </StackLayout>
        {(formProps.errors.day ||
          formProps.errors.month ||
          formProps.errors.year) && (
          <InputStatusMessage id="birthDate" inputStatus="error">
            {formatMessage({
              id:
                formProps.errors.month ||
                formProps.errors.day ||
                formProps.errors.year,
            })}
          </InputStatusMessage>
        )}
      </StackItem>
    </>
  );
};
