import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
  StatusBanner,
} from '@leagueplatform/genesis-core';
import { BackButton } from 'components/back-button/back-button.component';
import { APP_ROUTES } from 'common/constants';
import { PersonalInfoType } from './use-get-member-details.hook';

export const MemberDetailsHeader = ({
  personalInfo,
}: {
  personalInfo: PersonalInfoType | undefined;
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <StackLayout spacing="$two" css={{ width: '100%' }}>
      <StackItem>
        <BackButton
          onClick={() => history.push(APP_ROUTES.MEMBER_SEARCH)}
          buttonText={formatMessage({ id: 'MEMBER_SEARCH' })}
        />
      </StackItem>

      {personalInfo && !personalInfo?.loginId && (
        <StatusBanner
          icon="statusInformationCircleFill"
          showIcon
          status="info"
          title={formatMessage({ id: 'NO_DIGITAL_ACCOUNT_BANNER_TEXT' })}
          css={{ width: '100%' }}
        />
      )}

      {personalInfo && (
        <StackLayout
          orientation="horizontal"
          spacing="$one"
          css={{ alignItems: 'stretch' }}
        >
          <Card.Flat
            backgroundColor="secondary"
            css={{ minWidth: '56px', height: '56px', alignSelf: 'center' }}
          >
            <Card.Section padding="$threeQuarters" css={{ margin: 'auto' }}>
              <HeadingText
                level="1"
                size="lg"
                css={{ color: '$onSurfaceTextSubdued', lineHeight: 'normal' }}
              >
                {personalInfo.initials}
              </HeadingText>
            </Card.Section>
          </Card.Flat>
          <StackLayout verticalAlignment="center" css={{ alignSelf: 'center' }}>
            <HeadingText level="1" size="xl">
              {personalInfo.fullName}
            </HeadingText>
            {personalInfo?.loginId && (
              <ParagraphText
                size="base"
                css={{ color: '$onSurfaceTextSubdued' }}
              >
                {personalInfo?.loginId}
              </ParagraphText>
            )}
          </StackLayout>
        </StackLayout>
      )}
    </StackLayout>
  );
};
