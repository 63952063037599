import * as React from 'react';

import { Box, BoxProps } from '@leagueplatform/genesis-commons';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { APP_NAV_DEFAULT_HEIGHT } from '@highmark-web/highmark-common';
import { AppNavBarComponent } from 'components/nav/nav.component';

const MAIN_CONTENT_MIN_HEIGHT = `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`;

export const PrivateBasePage: React.FC<BoxProps> = ({
  children,
  ...boxProps
}) => {
  useScrollTopOnLocationChange();

  return (
    <>
      <AppNavBarComponent />
      <Box
        as="main"
        id="main-content"
        position="relative"
        flex="1"
        overflowX="hidden"
        // This is so any page will take up the whole screen regardless of content
        minHeight={MAIN_CONTENT_MIN_HEIGHT}
        {...boxProps} // eslint-disable-line
      >
        {children}
      </Box>
    </>
  );
};
