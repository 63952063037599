import { getBaseSupportToolSsoUrl } from 'common/constants';

export type SupportToolSsoParams = {
  toolName: string;
  ecId: string;
  loginId: string;
};

export const getCSAppSsoUrl = (params: SupportToolSsoParams) => {
  const url = new URL(getBaseSupportToolSsoUrl());

  const searchParams = new URLSearchParams();
  searchParams.set('Tool', params.toolName);
  searchParams.set('memberLoginID', params.loginId);
  searchParams.set('memberECID', params.ecId);
  url.search = searchParams.toString();

  return url.toString();
};
