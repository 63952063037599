import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { isDev } from '@leagueplatform/core';
import { highmarkFetch } from '@highmark-web/highmark-common';
import { ErrorContext } from '@leagueplatform/observability';
import { AGENT_TOKEN_CLAIM_KEY } from 'common/constants';

export const memberEligibilityFetch = async (
  path: String,
  options: RequestInit = {},
  errorContext: ErrorContext = {},
) => {
  let agentToken;

  // If local dev instance, grab token from local storage instead.
  // This is because getting token from auth session is only possible in VDI, in deployed apps.
  if (isDev()) {
    agentToken = localStorage.getItem('agentToken');
  } else {
    const idToken = await StandaloneAuth.client.getIdTokenClaims();
    agentToken = idToken?.[AGENT_TOKEN_CLAIM_KEY];
  }
  if (!agentToken) {
    throw new Error('No agent Token found in claims');
  }
  return highmarkFetch(
    path,
    agentToken,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      ...options,
    },
    errorContext,
  );
};
