import {
  InputLabel,
  InputStatusMessage,
  StackItem,
  TextInput,
} from '@leagueplatform/genesis-core';
import { FormikProps } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { MemberSearchFormProps } from 'store/member-search-form-store';

export const EcidSearchForm = ({
  formProps,
}: {
  formProps: FormikProps<MemberSearchFormProps>;
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackItem>
      <InputLabel htmlFor="ecid" required>
        {formatMessage({ id: 'ECID_INPUT_LABEL' })}
      </InputLabel>
      <TextInput
        id="ecid"
        css={{ minWidth: '437px' }}
        value={formProps.values.ecid}
        onChange={formProps.handleChange}
        maxLength={15}
      />
      {formProps.errors.ecid && (
        <InputStatusMessage inputStatus="error" id="ecid">
          {formatMessage({ id: formProps.errors.ecid })}
        </InputStatusMessage>
      )}
    </StackItem>
  );
};
