import {
  MemberSearchRequestBody,
  MemberSearchResponse,
} from 'types/members-api.types';
import { memberEligibilityFetch } from './member-search-fetch';

export const MEMBER_SEARCH_PATH = '/enrollment/v2/members';

// Some fields are not camelCase, this is to standardize the fields
export const normalizeResponseData = (data: any[]): MemberSearchResponse =>
  data.map((resultItem: any) => ({
    ...resultItem,
    loginId: resultItem?.login_id,
    ecId: resultItem?.ecid,
  }));

export const getMemberSearch = async (
  body: MemberSearchRequestBody,
): Promise<MemberSearchResponse> => {
  const response = await memberEligibilityFetch(MEMBER_SEARCH_PATH, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(
      `Error from request ${MEMBER_SEARCH_PATH}: ${response.status}`,
    );
  }

  const data = await response.json();

  if (!data && !(data instanceof Array)) {
    throw new Error(
      `Returned response from ${MEMBER_SEARCH_PATH} is not in expected array format.`,
    );
  }
  return normalizeResponseData(data);
};
