import {
  InputLabel,
  InputStatusMessage,
  StackItem,
  TextInput,
} from '@leagueplatform/genesis-core';
import { FormikProps } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { MemberSearchFormProps } from 'store/member-search-form-store';

export const UmiSearchForm = ({
  formProps,
}: {
  formProps: FormikProps<MemberSearchFormProps>;
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackItem>
      <InputLabel htmlFor="umi" required>
        {formatMessage({ id: 'UMI_INPUT_LABEL' })}
      </InputLabel>
      <TextInput
        id="umi"
        css={{ minWidth: '437px' }}
        value={formProps.values.umi}
        onChange={formProps.handleChange}
        placeholder="9999999999999"
      />
      {formProps.errors.umi && (
        <InputStatusMessage inputStatus="error" id="umi">
          {formatMessage({ id: formProps.errors.umi })}
        </InputStatusMessage>
      )}
    </StackItem>
  );
};
